@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&family=Monoton&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color: #FBFAF7;
  --primary-text-color: #000000;
  --secondary-text-color: #000000;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* For Firefox Browser */
  scrollbar-width: thin;
  scrollbar-color: currentColor #fff;
}

/* For Chrome, EDGE, Opera, Others */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

body {
  background-color: var(--theme-color);
  color: var(--primary-text-color);
  font-family: "Josefin Sans", sans-serif !important;
  min-height: 100vh; /* Use min-height instead of height */
  overflow-y: auto; /* Enable vertical scrolling */
}
.font-monoton {
  font-family: "Monoton", cursive;
}

input {
  @apply bg-white;
}
