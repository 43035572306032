@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

.App {
  font-family: "Open Sans", sans-serif;
}

.p-5 {
  padding-bottom: 20px;
}

.titleText {
  font-size: 15px;
  margin-bottom: 4px;
  color: rgb(60, 60, 60);
  font-weight: 400;
}

.commentText {
  font-size: 14px;
  color: rgb(124, 124, 124);
  font-weight: 500;
}

.descText {
  font-size: 12px;
  color: rgb(92, 92, 92);
}

.smallText {
  font-size: 10px;
  color: rgb(125, 125, 125);
}

.f10 {
  font-size: 12px;
  color: rgb(158, 158, 158);
}

.f14 {
  font-size: 14px;
  color: rgb(158, 158, 158);
}

.f20 {
  font-size: 20px;
  color: rgb(158, 158, 158);
}

.complaint_card {
  /* width: fit-content; */
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.info_card {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 10px 0px 0px;
  margin: -20px;
  margin-bottom: 15px;
}

.chartContainer {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 360px;
}
.chartInner {
  background: rgba(255, 128, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 22%;
  right: 20%;
  bottom: 20%;
  left: 20%;
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 120%;
  z-index: -1; /* Allow tooltips on top */
  overflow: hidden;
}
.chartStatus,
.chartValue {
  font-weight: bold;
}
.chartTarget,
.chartDaysLabel {
  font-size: 0.75em;
}
.chartDaysRemaining {
  color: #f90;
  font-weight: bold;
  margin-top: 0.25em;
}

.button-viztype {
  border: none;
  border-left: 7px solid;
}

.comment-card {
  border: none;
  border-left: 4px solid #98beff;
}

ion-label {
  font-family: "Poppins", Courier, monospace !important;
  font-size: 0.9rem !important;
  color: #606060 !important;
  font-weight: bold;
  overflow: hidden !important;
}

.notistack-SnackbarContainer.z-alert {
  z-index: 100000000 !important;
}

ion-input {
  font-family: "Poppins", Courier, monospace !important;
  font-size: 0.9rem !important;
  color: #353535 !important;
  /* font-weight: bold; */
}

ion-tabs {
  background-color: #d0e1ff;
}
ion-tab-bar {
  bottom: 20px;
  position: relative;
  border-radius: 16px;
  width: 92%;
  margin: 0 auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
ion-tab-button {
  --color: #adadad;
  --color-selected: #72a6ff;
}
ion-tab-button::before {
  background-color: transparent;
  display: block;
  content: "";
  margin: 0 auto;
  width: 20px;
  height: 2px;
}
ion-tab-button.tab-selected::before {
  background-color: #72a6ff;
}

.centered-row {
  display: flex;
  align-items: center;
}

ion-progress-bar {
  --background: #72a6ff;
  --buffer-background: #cbdeff;
  --progress-background: #72a6ff;
}

.round {
  --border-radius: 100%;
  --vertical-align: middle;
  /* --padding-start: 6px;
  --padding-end: 3px; */
}

/* Default styles for both laptop and mobile */
.card_wrapper {
  display: flex;
  justify-content: start;
}

.list_container {
  width: 100%;
  margin: auto;
}

/* Media query for laptop screens (min-width: 1024px) */
@media (min-width: 1024px) {
  .list_container {
    max-width: 60%;
  }
}

/* Media query for mobile screens (max-width: 767px) */
@media (max-width: 767px) {
  .list_container {
    max-width: 100%;
  }
}

.fixedLabel {
  min-width: 30% !important;
  max-width: 30% !important;
}

.add_button {
  height: 56px;
  background: #4b5053 !important;
  /* border-radius: 50px !important; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  display: block;
  /* margin: 10px !important; */
  width: 80%;
}

.css-1wc848c-MuiFormHelperText-root {
  color: rgba(255, 0, 0, 0.5) !important;
  font-size: 15px !important;
  font-style: bold !important;
}

/* ADMIN Card */

.titleTextAdmin {
  font-size: 30px;
  margin-bottom: 4px;
  color: rgb(60, 60, 60);
  font-weight: 400;
}

.smallTextAdmin {
  font-size: 15px;
  margin-bottom: 4px;
  color: rgb(60, 60, 60);
  font-weight: 700;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1;
  min-width: 40%; /* Each item takes at least 50% of the container width */
  padding: 10px;
  box-sizing: border-box;
  /* border: 1px solid #000; */
}

.adminIcons {
  opacity: 0.5;
}


.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 48px!important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg :hover {
  color: #606060 !important;
}

.image-gallery-fullscreen-button {
  background-color: white !important; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 10%; 
  padding: 0px; 
    color: black !important;
}

.image-gallery-fullscreen-button::before {
  color: black !important; 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}