:root {
	--ion-color-primary: #495054;
	--ion-color-primary-rgb: 73,80,84;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #40464a;
	--ion-color-primary-tint: #5b6265;

	--ion-color-secondary: #bccad0;
	--ion-color-secondary-rgb: 188,202,208;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #a5b2b7;
	--ion-color-secondary-tint: #c3cfd5;

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82,96,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	--ion-color-success: #9BD770;
	--ion-color-success-rgb: 155,215,112;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #88bd63;
	--ion-color-success-tint: #a5db7e;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-background-color: #E3E8EA;
	--ion-background-color-rgb: 227,232,234;

	--ion-text-color: #0d1d28;
	--ion-text-color-rgb: 65,71,75;

	--ion-color-step-50: #dbe0e2;
	--ion-color-step-100: #d3d8da;
	--ion-color-step-150: #cbd0d2;
	--ion-color-step-200: #c3c8ca;
	--ion-color-step-250: #bbc0c2;
	--ion-color-step-300: #b2b8ba;
	--ion-color-step-350: #aab0b2;
	--ion-color-step-400: #a2a8aa;
	--ion-color-step-450: #9aa0a2;
	--ion-color-step-500: #92989b;
	--ion-color-step-550: #8a8f93;
	--ion-color-step-600: #82878b;
	--ion-color-step-650: #7a7f83;
	--ion-color-step-700: #72777b;
	--ion-color-step-750: #6a6f73;
	--ion-color-step-800: #61676b;
	--ion-color-step-850: #595f63;
	--ion-color-step-900: #51575b;
	--ion-color-step-950: #494f53;
}



